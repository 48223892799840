import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    body:{
        "& a":{
          transition: 'all 0.5s ease',
          color: 'inherit',
          textDecoration: 'none',
          borderBottom: '1px solid currentColor'
        },
        "& a:hover":{
          background: '#FFCE3B',
        }
      }
}));


export default useStyles;