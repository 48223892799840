import React from 'react';
import Layout from '../components/layout';
import LanguageContext from "../context/LanguageContext";
import PageComponent from '../components/Page/PageComponent';
import Seo from '../components/seo';

const Page = ({pageContext}) => {

    return (
        <LanguageContext.Consumer>
        {language => (
    <Layout>
        <Seo title={pageContext.title} lang={language.english ? "en" : 'ga'}/>
        <PageComponent language={language} pageContext={pageContext} />
    </Layout>
     )}
     </LanguageContext.Consumer>
)}

export default Page;