import React,{useEffect, useRef} from 'react';
import { Typography, Container, } from '@mui/material';
import { navigate } from 'gatsby';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './styles';

const PageComponent = ({pageContext, language}) => {

  const classes = useStyles();

    const isInitialMount = useRef(true);

    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {   
        if (isInitialMount.current) {
          isInitialMount.current = false;
       } else {
        if(language.english){
            navigate(`/${pageContext.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`)
         }else{
            navigate(`/${pageContext.page_acf.titleIrish.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`)
         }
       }
      },[language.english]);

   
  
    return(
        <Container maxWidth="md" role="main">
            <Typography component="h1" style={{fontWeight:800, fontSize:matchesSm ? '42px' : '56px', marginTop:'15px'}}>{language.english ? pageContext.title : pageContext.page_acf.titleIrish }</Typography>
            <Typography className={classes.body} style={{fontSize:matchesSm ? '18px' : '21px', lineHeight:matchesSm ? '140%' : '30px'}} dangerouslySetInnerHTML={{__html:  language.english ? pageContext.page_acf.content : pageContext.page_acf.contentIrish}} /> 
        </Container>            
   )
}

export default PageComponent;